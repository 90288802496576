<template>
    <div>
    <v-card oncontextmenu="return false;" v-if="link" style="d-flex align-center">
        <video :key="key" ref="videoPlayer" class="video-js vjs-fluid vjs-big-play-centered"></video>
    </v-card>
    <p v-else class="mx-2 my-2">Invalid Video Link</p>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {
    name: "CustomVideoPlayer",
    props: {
      link: {
        type: String,
        default: "",
      }, 
     visible: {
        type: Boolean,
        default: null,
        },
     key: {
        type: String,
        default: null,
        },
    },

    data() {
    return {
        player: null,
    };
    },

    mounted() {
        this.player = videojs(this.$refs.videoPlayer, {
            preload: "none",
            autoplay: false,
            bigPlayButton: true,
            controls: true,
            controlBar: {
              'pictureInPictureToggle': false
            },
            sources: [
            {
                src:  this.link,
                type: 'video/mp4'
            }
            ]
        }, () => { this.player.log.level("off");
      });
    },
    beforeDestroy() {
     if (this.player) {
       this.player.dispose();
     }
    }
}
</script>

<style>

@import "https://vjs.zencdn.net/7.19.2/video-js.css"

</style>